.frame1-home-container {
	width: 100%;
	display: flex;
	overflow: auto;
	min-height: 100vh;
	align-items: center;
	flex-direction: column;
}
.frame1-home-frame1-home {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: rgba(255, 255, 255, 1);
}
.frame1-home-header {
	display: flex;
	padding: 16px 24px;
	overflow: hidden;
	align-self: stretch;
	align-items: center;
	flex-direction: column;
	width: 100%;
	border-bottom: 1px solid rgb(238, 238, 238);
}
.frame1-home-container1 {
	gap: 32px;
	max-width: 1200px;
	width: 100%;
	display: flex;
	overflow: hidden;
	align-items: center;

	justify-content: space-between;
}
.frame1-home-logo {
	gap: 12px;
	display: flex;
	padding: 4px;
	align-items: center;
	justify-content: center;
}
.frame1-home-logoborder1 {
	width: 69px;
	height: 58px;
}
.frame1-home-text {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 32px;
	text-align: left;
	font-weight: 700;
	line-height: 40px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-colright {
	gap: 48px;
	display: flex;
	align-items: center;
}
.frame1-home-menu {
	gap: 24px;
	display: flex;
	align-items: flex-start;
}
.frame1-home-menuitem {
	gap: 10px;
	display: flex;
	padding: 4px;
	align-items: flex-start;
}
.frame1-home-menuitem1 {
	gap: 10px;
	display: flex;
	padding: 4px;
	align-items: flex-start;
}
.frame1-home-text002 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	text-align: left;
	font-weight: 500;
	line-height: 28px;
	font-stretch: normal;
	transition: color 0.2s;
}
.frame1-home-text002:hover {
	color: var(--dl-color-main-primary);
}
.frame1-home-primary-button {
	gap: 10px;
	height: 48px;
	display: flex;
	padding: 12px 24px;
	align-items: center;

	border-radius: 4px;
	justify-content: center;
	background-color: var(--dl-color-main-primary);
}
.frame1-home-text004 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	text-align: left;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-section {
	display: flex;
	z-index: 1;
	align-self: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background-color: rgba(251, 250, 255, 1);
	background-size: cover;
	padding: 90px 0;
}
.frame1-home-container2 {
	gap: 10px;
	width: 100%;
	display: flex;
	overflow: hidden;
	align-items: flex-start;
	border-radius: 24px;
	flex-direction: column;
	max-width: 1200px;
}
.frame1-home-frame179 {
	gap: 10px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
}
.frame1-home-frame11 {
	gap: 40px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
}
.frame1-home-frame183 {
	width: 100%;
	display: flex;
	overflow: hidden;
	align-items: stretch;
}
.frame1-home-frame183 > div:nth-child(1) {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	flex: 1;
	padding: 0 24px;
	max-height: 400px;
	min-width: 500px;
}
.frame1-home-group81 {
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	flex-shrink: 1;
	margin-bottom: 24px;
}
.frame1-home-frame111 {
	gap: 12px;
	top: 362px;
	left: 0px;
	display: flex;
	position: absolute;
	align-items: flex-start;
}
.frame1-home-primary-button1 {
	gap: 10px;
	width: 232px;
	height: 60px;
	display: flex;
	padding: 12px 24px;
	align-items: center;

	border-radius: 8px;
	justify-content: center;
	background-color: var(--dl-color-main-primary);
}
.frame1-home-text006 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	text-align: left;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-secondary-button {
	gap: 10px;
	width: 232px;
	display: flex;
	padding: 12px 24px;
	align-items: center;

	border-color: rgba(0, 0, 0, 1);
	border-style: solid;
	border-width: 1px;
	border-radius: 8px;
	justify-content: center;
	background-color: var(--dl-color-main-white);
}
.frame1-home-text008 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 18px;
	text-align: left;
	font-weight: 500;
	line-height: 36px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text010 {
	color: rgba(0, 0, 0, 1);
	height: auto;
	align-self: stretch;
	text-align: left;
	line-height: 48px;
	font-size: 40px;
}
.frame1-home-text011 {
	color: var(--dl-color-main-body);
}
.frame1-home-text013 {
	top: 120px;
	color: var(--dl-color-main-body);
	height: auto;
	align-self: stretch;
	text-align: left;
	line-height: 32px;
	font-size: 20px;
	margin-top: 24px;
}
.frame1-home-group2 {
	top: 0px;
	left: 0px;
	width: 100%;
	display: flex;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-rectangle2 {
	top: 0px;
	left: 28.300283432006836px;
	width: 609px;
	height: 370px;
	position: absolute;
	border-radius: 16px 16px 2px 2px;
}
.frame1-home-rectangle4 {
	top: 390.5439147949219px;
	left: 0px;
	width: 666px;
	height: 21px;
	position: absolute;
	border-radius: 12px 12px 2px 2px;
}
.frame1-home-landing-page1-container {
	max-width: 90%;
}
.frame1-home-landing-page1 {
	max-width: 100%;
	object-fit: cover;
	object-position: top;
	max-height: 500px;
	padding: 0 24px;
}
.frame1-home-landing-page2 {
	height: 16px;
	width: 100%;
	background-color: #e0e0e0;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	margin-top: -8px;
}
.frame1-home-section1 {
	display: flex;
	z-index: 2;
	align-self: stretch;
	align-items: center;
	flex-direction: column;
	padding: 90px 0;
}
.frame1-home-container3 {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.frame1-home-title {
	gap: 24px;
	display: flex;
	padding: 0 0 48px;
	align-self: stretch;
	align-items: center;
	justify-content: center;
}
.frame1-home-divider {
	flex: 1;
	height: 2px;
	display: flex;
	overflow: hidden;
	position: relative;
	flex-grow: 1;
	align-items: flex-start;

	background-color: rgba(244, 245, 244, 1);
}
.frame1-home-text015 {
	color: var(--dl-color-main-body);
	height: auto;
	text-align: left;
	line-height: 36px;
}
.frame1-home-divider1 {
	flex: 1;
	height: 2px;
	display: flex;
	overflow: hidden;
	position: relative;
	flex-grow: 1;
	align-items: flex-start;
	background-color: rgba(244, 245, 244, 1);
}
.frame1-home-frame182 {
	gap: 24px;
	width: 100%;
	display: flex;
	padding: 10px;
	overflow-x: auto;
	align-items: center;

	justify-content: flex-start;
}
.frame1-home-image32 {
	width: 272px;
	height: 134px;
}
.frame1-home-image33 {
	width: 272px;
	height: 134px;
}
.frame1-home-image34 {
	width: 272px;
	height: 134px;
}
.frame1-home-image35 {
	width: 272px;
	height: 134px;
}
.frame1-home-section2 {
	display: flex;
	padding: 0 24px 90px;
	z-index: 3;
	align-self: stretch;
	align-items: center;
	flex-direction: column;
	max-width: 1200px;
	margin: 0 auto;
}
.frame1-home-container4 {
	display: flex;
	align-items: flex-start;
	width: 100%;
	flex-direction: column;
}
.frame1-home-oquee {
	gap: 24px;
	width: 100%;
	display: flex;
	align-items: center;

	justify-content: center;
	padding: 0 32px;
}
.frame1-home-title1 {
	display: flex;
	align-items: flex-start;

	flex-direction: column;
	flex: 1;
}
.frame1-home-text017 {
	color: var(--dl-color-main-primary);
	height: auto;
	font-size: 18px;
	text-align: left;
	font-weight: 600;
	line-height: 28px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text019 {
	max-width: 600px;
	height: auto;
	flex-grow: 1;
	text-align: left;
	font-weight: 500;
	line-height: 48px;
}
.frame1-home-text020 {
	color: var(--dl-color-main-primary);
}
.frame1-home-text022 {
	color: var(--dl-color-main-body);
	max-width: 572px;
	height: auto;
	font-size: 18px;
	text-align: left;
	font-weight: 400;
	line-height: 28px;
	font-stretch: normal;
	text-decoration: none;
	margin-top: 32px;
}
.frame1-home-text023 {
	font-weight: 400;
}
.frame1-home-text024 {
	font-weight: 700;
}
.frame1-home-text025 {
	font-weight: 400;
}
.frame1-home-text027 {
	font-weight: 500;
}
.frame1-home-text028 {
	font-weight: 700;
}
.frame1-home-mockup-desktop-container {
	display: flex;
	position: relative;
	align-items: center;
	flex-direction: column;
	flex-shrink: 1;
	width: 100%;
	max-width: 500px;
}
.frame1-home-mockup-desktop-container-img {
	width: 90%;
	aspect-ratio: 4 / 2.5;
	border-radius: 12px 12px 0 0;
	background-color: #f5f5f5;
	max-width: 450px;
}
.frame1-home-mockup-desktop {
	border-radius: 12px 12px 0 0;
	border: 8px solid #e0e0e0;
	max-width: 100%;
	aspect-ratio: 4 / 2.5;
	object-fit: cover;
	object-position: top;
}
.frame1-home-mockup-desktop-image {
	height: 16px;
	width: 100%;
	background-color: #e0e0e0;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	margin-top: -8px;
	max-width: 500px;
}
.frame1-home-rectangle21 {
	top: 0px;
	left: 33.32136535644531px;
	width: 513px;
	height: 332px;
	position: absolute;
	border-radius: 14px 14px 2px 2px;
}
.frame1-home-rectangle41 {
	top: 320px;
	left: 0px;
	height: 18px;
	position: absolute;
	border-radius: 8px 8px 2px 2px;
	z-index: 1;
}
.frame1-home-rectangle3 {
	top: 6.461538314819336px;
	left: 39.56911849975586px;
	width: 501px;
	height: 319px;
	position: absolute;
	border-radius: 6px;
}
.frame1-home-ul {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: stretch;
	margin: 0 auto;
	width: 100%;
	align-self: center;
	gap: 12px;
}
.frame1-home-li {
	gap: 10px;
	min-height: 120px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: center;

	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid #eee;
}
.frame1-home-row {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-col {
	gap: 10px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text030 {
	color: var(--dl-color-main-primary);
	height: auto;
	font-size: 24px;
	text-align: left;
	font-weight: 700;
	line-height: 28px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-col01 {
	gap: 10px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-text032 {
	color: var(--dl-color-main-body);
	height: auto;
	text-align: left;
	line-height: 28px;
}
.frame1-home-li01 {
	gap: 10px;
	height: 120px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: center;

	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid #eee;
}
.frame1-home-row1 {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-col02 {
	gap: 10px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text034 {
	color: var(--dl-color-main-primary);
	height: auto;
	font-size: 24px;
	text-align: left;
	font-weight: 700;
	line-height: 28px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-col03 {
	gap: 10px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-text036 {
	color: var(--dl-color-main-body);
	height: auto;
	text-align: left;
	line-height: 28px;
}
.frame1-home-li02 {
	gap: 10px;
	height: 120px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: center;

	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid #eee;
}
.frame1-home-row2 {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-col04 {
	gap: 10px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text038 {
	color: var(--dl-color-main-primary);
	height: auto;
	font-size: 24px;
	text-align: left;
	font-weight: 700;
	line-height: 28px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-col05 {
	gap: 10px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-text040 {
	color: var(--dl-color-main-body);
	height: auto;
	text-align: left;
	line-height: 28px;
}
.frame1-home-li03 {
	gap: 10px;
	height: 120px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: center;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid #eee;
}
.frame1-home-row3 {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-col06 {
	gap: 10px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text042 {
	color: var(--dl-color-main-primary);
	height: auto;
	font-size: 24px;
	text-align: left;
	font-weight: 700;
	line-height: 28px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-col07 {
	gap: 10px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-text044 {
	color: var(--dl-color-main-body);
	height: auto;
	text-align: left;
	line-height: 28px;
}
.frame1-home-section3 {
	display: flex;
	padding: 200px 0;
	z-index: 4;
	overflow: hidden;
	align-self: stretch;
	background: linear-gradient(
		180deg,
		rgba(251, 250, 255, 1) 0%,
		rgba(255, 255, 255, 1) 100%
	);
	align-items: center;
	border-radius: 250px 0 0;
	flex-direction: column;
	background-color: var(--dl-color-default-2);
}
.frame1-home-container5 {
	gap: 40px;
	max-width: 1200px;
	display: flex;
	align-items: flex-start;
	padding: 0 56px;
	flex-direction: column;
}
.frame1-home-title2 {
	gap: 12px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-frame15 {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text046 {
	color: var(--dl-color-main-primary);
	height: auto;
	font-size: 18px;
	text-align: left;
	font-weight: 600;
	line-height: 28px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text048 {
	color: rgba(0, 0, 0, 1);
	height: auto;
	text-align: left;
	line-height: 48px;
	font-size: 40px;
	font-weight: 500;
}
.frame1-home-text049 {
	color: var(--dl-color-main-body);
}
.frame1-home-text051 {
	color: var(--dl-color-main-body);
	height: auto;
	text-align: left;
	line-height: 28px;
	font-size: 20px;
}
.frame1-home-ul1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: stretch;
	gap: 20px;
	width: 100%;
}
.frame1-home-li04 {
	gap: 20px;
	display: flex;
	align-items: flex-start;
}
.frame1-home-li05 {
	gap: 10px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: flex-start;

	border-radius: 8px;
	background-color: var(--dl-color-main-white);
}
.frame1-home-frame13 {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-frame16 {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-coco-line-tick {
	width: 24px;
	height: 24px;
}
.frame1-home-frame14 {
	gap: 4px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text053 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	align-self: stretch;
	text-align: left;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text055 {
	color: var(--dl-color-main-gray);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-li06 {
	gap: 10px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: flex-start;
	border-radius: 8px;
	background-color: var(--dl-color-main-white);
}
.frame1-home-frame131 {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-frame161 {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-coco-line-tick1 {
	width: 24px;
	height: 24px;
}
.frame1-home-frame141 {
	gap: 4px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text057 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	align-self: stretch;
	text-align: left;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text059 {
	color: var(--dl-color-main-gray);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-li07 {
	gap: 20px;
	display: flex;
	align-items: flex-start;
}
.frame1-home-li08 {
	gap: 10px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: flex-start;

	border-radius: 8px;
	background-color: var(--dl-color-main-white);
}
.frame1-home-frame132 {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-frame162 {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-coco-line-tick2 {
	width: 24px;
	height: 24px;
}
.frame1-home-frame142 {
	gap: 4px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;

	flex-direction: column;
}
.frame1-home-text061 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	align-self: stretch;
	text-align: left;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text063 {
	color: var(--dl-color-main-gray);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-li09 {
	gap: 10px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: flex-start;

	border-radius: 8px;
	background-color: var(--dl-color-main-white);
}
.frame1-home-frame133 {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-frame163 {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-coco-line-tick3 {
	width: 24px;
	height: 24px;
}
.frame1-home-frame143 {
	gap: 4px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;

	flex-direction: column;
}
.frame1-home-text065 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	align-self: stretch;
	text-align: left;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text067 {
	color: var(--dl-color-main-gray);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-li10 {
	gap: 20px;
	display: flex;
	align-items: flex-start;
}
.frame1-home-li11 {
	gap: 10px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: flex-start;

	border-radius: 8px;
	background-color: var(--dl-color-main-white);
}
.frame1-home-frame134 {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-frame164 {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-coco-line-tick4 {
	width: 24px;
	height: 24px;
}
.frame1-home-frame144 {
	gap: 4px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;

	flex-direction: column;
}
.frame1-home-text069 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	align-self: stretch;
	text-align: left;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text071 {
	color: var(--dl-color-main-gray);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-li12 {
	gap: 10px;
	display: flex;
	padding: 24px;
	box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05000000074505806);
	align-items: flex-start;

	border-radius: 8px;
	background-color: var(--dl-color-main-white);
}
.frame1-home-frame135 {
	gap: 12px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;
}
.frame1-home-frame165 {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-coco-line-tick5 {
	width: 24px;
	height: 24px;
}
.frame1-home-frame145 {
	gap: 4px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;

	flex-direction: column;
}
.frame1-home-text073 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	align-self: stretch;
	text-align: left;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text075 {
	color: var(--dl-color-main-gray);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-buttons {
	gap: 12px;
	display: flex;
	align-items: flex-start;
}
.frame1-home-primary-button2 {
	gap: 10px;
	width: 232px;
	display: flex;
	padding: 12px 24px;
	align-items: center;
	border-radius: 8px;
	justify-content: center;
	background-color: var(--dl-color-main-primary);
}
.frame1-home-text077 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 18px;
	text-align: left;
	font-weight: 500;
	line-height: 36px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-section4 {
	display: flex;
	padding: 0 0 200px;
	z-index: 5;
	align-self: stretch;
	align-items: center;
}
.frame1-home-containerfluid {
	display: flex;
	position: relative;
	flex-grow: 1;
	align-items: center;
}
.frame1-home-img {
	max-width: 50%;
	padding-right: 50px;
}
.frame1-home-defipielogobg {
	width: 493px;
	height: 565px;
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: flex-start;
}
.frame1-home-group79 {
	top: 0.010486088693141937px;
	left: 0px;
	width: 489.15045166015625px;
	height: 562.5073852539062px;
	display: flex;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-group4 {
	top: 0px;
	left: 0px;
	width: 489.15045166015625px;
	height: 562.5073852539062px;
	display: flex;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-group3 {
	top: 0px;
	left: 0px;
	width: 489.15045166015625px;
	height: 562.5073852539062px;
	display: flex;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-path4 {
	top: 0px;
	left: 0px;
	width: 489px;
	height: 267px;
	position: absolute;
}
.frame1-home-path5 {
	top: 216.00100708007812px;
	left: 0.020118575543165207px;
	width: 489px;
	height: 347px;
	position: absolute;
}
.frame1-home-path6 {
	top: 194.25526428222656px;
	left: 233.1520538330078px;
	width: 256px;
	height: 184px;
	position: absolute;
}
.frame1-home-image31 {
	border-top-right-radius: 200px;
	border-bottom-right-radius: 200px;
	max-width: 100%;
}
.frame1-home-col08-container {
	flex: 1;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.frame1-home-col08 {
	gap: 32px;
	display: flex;
	z-index: 2;
	align-items: flex-start;
	flex-direction: column;
	background-color: #fff;
	border-radius: 16px;
	padding: 32px;
	opacity: 0.95;
}
.frame1-home-frame80 {
	gap: 16px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-logo1 {
	gap: 12px;
	width: 275px;
	height: 120px;
	display: flex;
	padding: 4px;
	align-items: center;

	justify-content: center;
}
.frame1-home-logoborder11 {
	width: auto;
	height: auto;
	flex-grow: 1;
	align-self: stretch;
}
.frame1-home-text079 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 48px;
	text-align: left;
	font-weight: 700;
	line-height: 40px;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-text081 {
	height: auto;
	text-align: left;
	line-height: 48px;
	font-size: 40px;
	font-weight: 500;
}
.frame1-home-text082 {
	color: var(--dl-color-main-primary);
}
.frame1-home-text084 {
	color: var(--dl-color-main-body);
	max-width: 579px;
	height: auto;
	text-align: left;
	line-height: 32px;
	font-size: 18px;
}
.frame1-home-sectionjoinus {
	gap: 10px;
	display: flex;
	align-items: center;
	z-index: 6;
	overflow: hidden;
	position: relative;
	align-self: stretch;
	border-radius: 250px 0 0;
	flex-direction: column;
	background-color: var(--dl-color-main-body);
}
.frame1-home-bg {
	top: -1277px;
	left: -1198px;
	width: 3734px;
	height: 3882.825927734375px;
	display: flex;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-bg1 {
	top: 2418.578369140625px;
	left: 1432.730712890625px;
	width: 1879.1546630859375px;
	height: 1879.1546630859375px;
	display: flex;
	opacity: 0.6;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-vector {
	top: -1877.0086664302216px;
	left: -644.1513454336746px;
	width: 1467px;
	height: 1190px;
	position: absolute;
}
.frame1-home-vector01 {
	top: -1866.379217286245px;
	left: -618.908574355155px;
	width: 1438px;
	height: 1155px;
	position: absolute;
}
.frame1-home-vector02 {
	top: -1857.6823167036637px;
	left: -593.6673916773725px;
	width: 1411px;
	height: 1120px;
	position: absolute;
}
.frame1-home-vector03 {
	top: -1848.0190240068478px;
	left: -568.4254615168902px;
	width: 1382px;
	height: 1086px;
	position: absolute;
}
.frame1-home-vector04 {
	top: -1839.322830425488px;
	left: -545.125211104496px;
	width: 1356px;
	height: 1055px;
	position: absolute;
}
.frame1-home-vector05 {
	top: -1831.5918506229937px;
	left: -520.854074100389px;
	width: 1331px;
	height: 1022px;
	position: absolute;
}
.frame1-home-vector06 {
	top: -1823.8615778217209px;
	left: -501.43793066687067px;
	width: 1306px;
	height: 996px;
	position: absolute;
}
.frame1-home-vector07 {
	top: -1817.0975400233px;
	left: -487.8457986889043px;
	width: 1289px;
	height: 975px;
	position: absolute;
}
.frame1-home-vector08 {
	top: -1811.2998157834227px;
	left: -476.1959070710509px;
	width: 1277px;
	height: 956px;
	position: absolute;
}
.frame1-home-vector09 {
	top: -1805.502012987854px;
	left: -468.42909464336117px;
	width: 1265px;
	height: 942px;
	position: absolute;
}
.frame1-home-vector10 {
	top: -1799.7038174138288px;
	left: -461.63335567805916px;
	width: 1253px;
	height: 928px;
	position: absolute;
}
.frame1-home-vector11 {
	top: -1794.8720139540383px;
	left: -455.80840986913245px;
	width: 1241px;
	height: 916px;
	position: absolute;
}
.frame1-home-vector12 {
	top: -1790.0405247170129px;
	left: -451.9246766316064px;
	width: 1231px;
	height: 906px;
	position: absolute;
}
.frame1-home-vector13 {
	top: -1786.1755847056047px;
	left: -448.0413171354303px;
	width: 1222px;
	height: 896px;
	position: absolute;
}
.frame1-home-vector14 {
	top: -1782.3107232498878px;
	left: -444.1582379452666px;
	width: 1213px;
	height: 886px;
	position: absolute;
}
.frame1-home-vector15 {
	top: -1779.4111541287275px;
	left: -440.274785013753px;
	width: 1204px;
	height: 876px;
	position: absolute;
}
.frame1-home-vector16 {
	top: -1775.5466068957758px;
	left: -437.36268585063954px;
	width: 1196px;
	height: 867px;
	position: absolute;
}
.frame1-home-vector17 {
	top: -1773.6138226673065px;
	left: -433.4789526131135px;
	width: 1189px;
	height: 858px;
	position: absolute;
}
.frame1-home-vector18 {
	top: -1770.71448921322px;
	left: -430.5665731439876px;
	width: 1182px;
	height: 849px;
	position: absolute;
}
.frame1-home-vector19 {
	top: -1768.782019207516px;
	left: -427.6536330628369px;
	width: 1176px;
	height: 841px;
	position: absolute;
}
.frame1-home-vector20 {
	top: -1766.8490778676642px;
	left: -424.741253593711px;
	width: 1171px;
	height: 833px;
	position: absolute;
}
.frame1-home-vector21 {
	top: -1750.4222187065752px;
	left: -410.1788890713942px;
	width: 1145px;
	height: 806px;
	position: absolute;
}
.frame1-home-vector22 {
	top: -1733.9948096556473px;
	left: -395.616244243065px;
	width: 1117px;
	height: 778px;
	position: absolute;
}
.frame1-home-vector23 {
	top: -1718.5338712746452px;
	left: -381.0535059793983px;
	width: 1092px;
	height: 752px;
	position: absolute;
}
.frame1-home-vector24 {
	top: -1702.106697890791px;
	left: -367.4621214841318px;
	width: 1064px;
	height: 726px;
	position: absolute;
}
.frame1-home-vector25 {
	top: -1685.6794459512457px;
	left: -352.8992897851276px;
	width: 1037px;
	height: 698px;
	position: absolute;
}
.frame1-home-vector26 {
	top: -1669.2520369003178px;
	left: -338.3366449567984px;
	width: 1010px;
	height: 671px;
	position: absolute;
}
.frame1-home-vector27 {
	top: -1652.8250991835375px;
	left: -323.77381325779425px;
	width: 983px;
	height: 644px;
	position: absolute;
}
.frame1-home-vector28 {
	top: -1637.364003691153px;
	left: -310.1824287625277px;
	width: 958px;
	height: 617px;
	position: absolute;
}
.frame1-home-vector29 {
	top: -1620.937144530064px;
	left: -295.6200642402109px;
	width: 930px;
	height: 590px;
	position: absolute;
}
.frame1-home-vector30 {
	top: -1604.5094998120621px;
	left: -281.0575128472192px;
	width: 904px;
	height: 563px;
	position: absolute;
}
.frame1-home-vector31 {
	top: -1588.0824049838993px;
	left: -267.4658480459402px;
	width: 876px;
	height: 538px;
	position: absolute;
}
.frame1-home-vector32 {
	top: -1572.6214666028973px;
	left: -252.90329665294848px;
	width: 850px;
	height: 514px;
	position: absolute;
}
.frame1-home-vector33 {
	top: -1556.1937433292042px;
	left: -239.31144498099457px;
	width: 824px;
	height: 492px;
	position: absolute;
}
.frame1-home-vector34 {
	top: -1540.7331191709673px;
	left: -224.74870671732788px;
	width: 797px;
	height: 472px;
	position: absolute;
}
.frame1-home-vector35 {
	top: -1525.2720236785826px;
	left: -211.1571353513864px;
	width: 772px;
	height: 452px;
	position: absolute;
}
.frame1-home-bg2 {
	top: 2939.025390625px;
	left: 3745.71435546875px;
	width: 1875.11376953125px;
	height: 1880.58349609375px;
	display: flex;
	opacity: 0.6;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-vector36 {
	top: -16.14120013059437px;
	left: -1992.6314075014052px;
	width: 1470px;
	height: 1195px;
	position: absolute;
}
.frame1-home-vector37 {
	top: -36.43598137678782px;
	left: -1974.1139815683528px;
	width: 1440px;
	height: 1160px;
	position: absolute;
}
.frame1-home-vector38 {
	top: -57.36130592120753px;
	left: -1957.4250268436954px;
	width: 1413px;
	height: 1125px;
	position: absolute;
}
.frame1-home-vector39 {
	top: -77.97368008777266px;
	left: -1939.8215551670874px;
	width: 1385px;
	height: 1091px;
	position: absolute;
}
.frame1-home-vector40 {
	top: -97.0751316492242px;
	left: -1923.7835813165948px;
	width: 1358px;
	height: 1059px;
	position: absolute;
}
.frame1-home-vector41 {
	top: -117.40070556877617px;
	left: -1908.3341519387723px;
	width: 1333px;
	height: 1027px;
	position: absolute;
}
.frame1-home-vector42 {
	top: -133.17372523433733px;
	left: -1894.512487101205px;
	width: 1308px;
	height: 1000px;
	position: absolute;
}
.frame1-home-vector43 {
	top: -143.76024574571056px;
	left: -1883.5556166692113px;
	width: 1291px;
	height: 979px;
	position: absolute;
}
.frame1-home-vector44 {
	top: -152.81999151519994px;
	left: -1874.1646898594518px;
	width: 1279px;
	height: 960px;
	position: absolute;
}
.frame1-home-vector45 {
	top: -158.23962485546508px;
	left: -1866.0738464909264px;
	width: 1266px;
	height: 946px;
	position: absolute;
}
.frame1-home-vector46 {
	top: -162.74272230083443px;
	left: -1858.3086219772485px;
	width: 1255px;
	height: 932px;
	position: absolute;
}
.frame1-home-vector47 {
	top: -166.65766586390237px;
	left: -1851.783572239572px;
	width: 1243px;
	height: 920px;
	position: absolute;
}
.frame1-home-vector48 {
	top: -168.7438738650817px;
	left: -1845.909725436868px;
	width: 1232px;
	height: 910px;
	position: absolute;
}
.frame1-home-vector49 {
	top: -171.14358361042105px;
	left: -1840.9494453287043px;
	width: 1224px;
	height: 900px;
	position: absolute;
}
.frame1-home-vector50 {
	top: -173.54778835714387px;
	left: -1835.9895766646732px;
	width: 1214px;
	height: 890px;
	position: absolute;
}
.frame1-home-vector51 {
	top: -176.27120132492564px;
	left: -1831.9437577860008px;
	width: 1206px;
	height: 880px;
	position: absolute;
}
.frame1-home-vector52 {
	top: -177.75885349989403px;
	left: -1827.3100166243676px;
	width: 1197px;
	height: 871px;
	position: absolute;
}
.frame1-home-vector53 {
	top: -180.79403327922046px;
	left: -1824.179554761904px;
	width: 1191px;
	height: 862px;
	position: absolute;
}
.frame1-home-vector54 {
	top: -182.59998851434648px;
	left: -1820.4592638181603px;
	width: 1183px;
	height: 853px;
	position: absolute;
}
.frame1-home-vector55 {
	top: -184.7184207008031px;
	left: -1817.6538258520704px;
	width: 1178px;
	height: 845px;
	position: absolute;
}
.frame1-home-vector56 {
	top: -186.83730963692506px;
	left: -1814.8485605078276px;
	width: 1173px;
	height: 837px;
	position: absolute;
}
.frame1-home-vector57 {
	top: -195.15083730458718px;
	left: -1794.4199727980995px;
	width: 1146px;
	height: 810px;
	position: absolute;
}
.frame1-home-vector58 {
	top: -203.47050862803735px;
	left: -1773.9908747663176px;
	width: 1118px;
	height: 782px;
	position: absolute;
}
.frame1-home-vector59 {
	top: -212.10271816574095px;
	left: -1754.476524128033px;
	width: 1093px;
	height: 755px;
	position: absolute;
}
.frame1-home-vector60 {
	top: -219.51094454804843px;
	left: -1734.3729092012145px;
	width: 1065px;
	height: 729px;
	position: absolute;
}
.frame1-home-vector61 {
	top: -227.82570870651398px;
	left: -1713.9436301668466px;
	width: 1039px;
	height: 701px;
	position: absolute;
}
.frame1-home-vector62 {
	top: -236.145204579625px;
	left: -1693.5145945637596px;
	width: 1011px;
	height: 674px;
	position: absolute;
}
.frame1-home-vector63 {
	top: -244.45887144999142px;
	left: -1673.0852870381677px;
	width: 984px;
	height: 647px;
	position: absolute;
}
.frame1-home-vector64 {
	top: -252.18161058634723px;
	left: -1653.8961358525958px;
	width: 959px;
	height: 620px;
	position: absolute;
}
.frame1-home-vector65 {
	top: -260.50086974619626px;
	left: -1633.4672682623604px;
	width: 931px;
	height: 593px;
	position: absolute;
}
.frame1-home-vector66 {
	top: -268.81535260533565px;
	left: -1613.0377541774506px;
	width: 904px;
	height: 566px;
	position: absolute;
}
.frame1-home-vector67 {
	top: -276.2225346240375px;
	left: -1592.9342594990267px;
	width: 877px;
	height: 541px;
	position: absolute;
}
.frame1-home-vector68 {
	top: -284.8530592597017px;
	left: -1573.4201732397596px;
	width: 851px;
	height: 517px;
	position: absolute;
}
.frame1-home-vector69 {
	top: -292.25176928822475px;
	left: -1553.3159227107608px;
	width: 825px;
	height: 495px;
	position: absolute;
}
.frame1-home-vector70 {
	top: -300.88403175042185px;
	left: -1533.8017208120946px;
	width: 798px;
	height: 474px;
	position: absolute;
}
.frame1-home-container6 {
	display: flex;
	z-index: 1;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
	max-width: 1200px;
	padding: 140px;
	gap: 48px;
}
.frame1-home-col09 {
	gap: 64px;
	display: flex;
	align-items: flex-start;
	flex: 1;
	flex-direction: column;
	max-width: 600px;
}
.frame1-home-title3 {
	gap: 10px;
	display: flex;
	align-self: stretch;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-frame12 {
	gap: 12px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text088 {
	max-width: 493px;
	height: auto;
	text-align: left;
	line-height: 48px;
	font-size: 40px;
	font-weight: 500;
}
.frame1-home-text089 {
	color: var(--dl-color-main-primary);
}
.frame1-home-text091 {
	color: #fff;
}
.frame1-home-text093 {
	color: var(--dl-color-main-graylight);
	height: auto;
	text-align: left;
	line-height: 28px;
}
.frame1-home-form {
	gap: 40px;
	display: flex;
	align-self: stretch;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-row4 {
	gap: 20px;
	display: grid;
	align-self: stretch;
	grid-template-columns: 1fr 1fr;
}
#mc_embed_shell {
	width: 100%;
}
.frame1-home-col10 {
	gap: 24px;
	width: 340px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;

	flex-direction: column;
}
.frame1-home-input {
	gap: 4px;
	display: flex;
	align-self: stretch;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text095 {
	color: var(--dl-color-main-graylight);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-input1 {
	gap: 10px;
	display: flex;
	padding: 14px 12px;
	align-self: stretch;
	align-items: flex-start;

	border-color: rgba(244, 245, 244, 1);
	border-style: solid;
	border-width: 2px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
}
.frame1-home-input2 {
	gap: 4px;
	display: flex;
	align-self: stretch;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text097 {
	color: var(--dl-color-main-graylight);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-input3 {
	gap: 10px;
	display: flex;
	padding: 14px 12px;
	align-self: stretch;
	align-items: flex-start;

	border-color: rgba(244, 245, 244, 1);
	border-style: solid;
	border-width: 2px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
}
.frame1-home-col11 {
	gap: 24px;
	width: 340px;
	display: flex;
	flex-grow: 1;
	align-items: flex-start;

	flex-direction: column;
}
.frame1-home-input4 {
	gap: 4px;
	display: flex;
	align-self: stretch;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text099 {
	color: var(--dl-color-main-graylight);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-input5 {
	gap: 10px;
	display: flex;
	padding: 14px 12px;
	align-self: stretch;
	align-items: flex-start;

	border-color: rgba(244, 245, 244, 1);
	border-style: solid;
	border-width: 2px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
}
.frame1-home-input6 {
	gap: 4px;
	display: flex;
	align-self: stretch;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-text101 {
	color: var(--dl-color-main-graylight);
	height: auto;
	text-align: left;
	line-height: 24px;
}
.frame1-home-input7 {
	gap: 10px;
	display: flex;
	padding: 14px 12px;
	align-self: stretch;
	align-items: flex-start;

	border-color: rgba(244, 245, 244, 1);
	border-style: solid;
	border-width: 2px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
}
.frame1-home-button {
	gap: 10px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.frame1-home-primary-button3 {
	gap: 10px;
	width: 232px;
	height: 48px;
	display: flex;
	padding: 12px 24px;
	align-items: center;

	border-radius: 8px;
	justify-content: center;
	background-color: var(--dl-color-main-primary);
}
.frame1-home-text103 {
	color: var(--dl-color-main-body);
	height: auto;
	font-size: 16px;
	text-align: left;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
	text-decoration: none;
}
.frame1-home-col12 {
	gap: 24px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}
.frame1-home-row5 {
	gap: 12px;
	display: flex;
	align-items: center;

	justify-content: flex-end;
}
.frame1-home-social {
	width: 32px;
	height: 32px;
	display: flex;
	position: relative;
	align-items: flex-start;
}
.frame1-home-facebook {
	top: 0px;
	left: 0px;
	width: 32px;
	height: 32px;
	display: flex;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-combined-shape {
	top: 0px;
	left: 0px;
	width: 32px;
	height: 32px;
	position: absolute;
}
.frame1-home-social1 {
	width: 32px;
	height: 32px;
	display: flex;
	position: relative;
	align-items: flex-start;
}
.frame1-home-twitter {
	top: 0px;
	left: 0px;
	width: 32px;
	height: 32px;
	display: flex;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-combined-shape1 {
	top: 0px;
	left: 0px;
	width: 32px;
	height: 32px;
	position: absolute;
}
.frame1-home-social2 {
	width: 32px;
	height: 32px;
	display: flex;
	position: relative;
	align-items: flex-start;
}
.frame1-home-telegram {
	top: 0px;
	left: 0px;
	width: 32px;
	height: 32px;
	display: flex;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-combined-shape2 {
	top: 0px;
	left: 0px;
	width: 32px;
	height: 32px;
	position: absolute;
}
.frame1-home-social3 {
	width: 32px;
	height: 32px;
	display: flex;
	position: relative;
	align-items: flex-start;
}
.frame1-home-github {
	top: 0.11996609717607498px;
	left: 0.058446284383535385px;
	width: 31.940494537353516px;
	height: 31.684173583984375px;
	display: flex;
	position: absolute;
	align-items: flex-start;
	flex-shrink: 1;
}
.frame1-home-fill51 {
	top: 0px;
	left: 0px;
	width: 32px;
	height: 32px;
	position: absolute;
}
.frame1-home-fill52 {
	top: 23.046188354492188px;
	left: 5.621085166931152px;
	width: 1px;
	height: 1px;
	position: absolute;
}
.frame1-home-fill53 {
	top: 23.604957580566406px;
	left: 6.275784492492676px;
	width: 1px;
	height: 1px;
	position: absolute;
}
.frame1-home-fill54 {
	top: 24.435256958007812px;
	left: 6.895468711853027px;
	width: 1px;
	height: 1px;
	position: absolute;
}
.frame1-home-fill55 {
	top: 25.35854721069336px;
	left: 7.6400933265686035px;
	width: 1px;
	height: 1px;
	position: absolute;
}
.frame1-home-fill56 {
	top: 26.064661026000977px;
	left: 8.710077285766602px;
	width: 1px;
	height: 1px;
	position: absolute;
}
.frame1-home-fill57 {
	top: 26.279741287231445px;
	left: 9.998809814453125px;
	width: 1px;
	height: 1px;
	position: absolute;
}
.frame1-home-fill58 {
	top: 26.12047004699707px;
	left: 11.220442771911621px;
	width: 1px;
	height: 1px;
	position: absolute;
}
.frame1-home-row6 {
	gap: 20px;
	display: flex;
	align-items: flex-end;
  flex-direction: column;
}
.frame1-home-row6 > div {
  display: flex;
  align-items: center;
  gap: 8px;
}
.frame1-home-row6 .separator {
  color: rgba(211, 205, 239, 1);
  opacity: .5;
}
.frame1-home-row6
.link {
	color: rgba(211, 205, 239, 1);
	height: auto;
	font-size: 14px;
	text-align: right;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
}
.link:hover {
	text-decoration: underline;
}
.frame1-home-grid {
	width: 1440px;
	height: 5699px;
	display: flex;
	z-index: 7;
	overflow: hidden;
	position: relative;
	align-items: flex-start;
}
.section-1-button-containers {
	display: flex;
	align-items: flex-start;
	gap: 16px;
	flex-wrap: wrap;
}

@media (max-width: 1000px) {
	.frame1-home-ul1 {
		grid-template-columns: 1fr;
	}
	.frame1-home-oquee {
		flex-direction: column;
	}
	.frame1-home-mockup-desktop-container {
		max-width: 90% !important;
	}
	.frame1-home-frame183 {
		flex-direction: column;
		flex-wrap: wrap;
		gap: 70px;
	}
	.frame1-home-frame183 > div:nth-child(1) {
		align-items: center;
	}
	.frame1-home-landing-page1 {
		margin: 0 auto;
	}
	.section-1-signup-button {
		display: none;
	}
	.frame1-home-group81 span {
		text-align: center;
	}
	.frame1-home-frame183 > div > a {
		display: none;
	}
	.frame1-home-container6 {
		flex-direction: column;
		align-items: initial;
	}
	.frame1-home-col08 {
		position: relative;
	}
	.frame1-home-containerfluid {
		flex-direction: column;
	}
	.frame1-home-col08 {
		max-width: 100%;
	}
	.frame1-home-img {
		margin-top: 70px;
		max-width: 90%;
		margin-right: auto;
	}
	.frame1-home-col08 {
		margin-top: -108px !important;
		backdrop-filter: blur(8px);
		-webkit-backdrop-filter: blur(8px);
		background-color: hsla(0, 0%, 100%, 0.8);
	}
}
@media (max-width: 800px) {
	.frame1-home-ul {
		grid-template-columns: 1fr;
	}
	.frame1-home-li {
		max-width: 100%;
	}
	.frame1-home-row4 {
		grid-template-columns: 1fr !important;
	}
}
@media (max-width: 700px) {
	.frame1-home-col08 {
		margin: 0 24px;
	}
}
@media (max-width: 600px) {
	.frame1-home-container6 {
		padding: 140px 60px;
	}
	.frame1-home-frame12 {
		align-items: center !important;
	}
	.frame1-home-text088 {
		text-align: center !important;
	}
	.header-buttons-container .frame1-home-colright {
		display: none !important;
	}
	.signup-button-section-1 {
		display: block !important;
		margin-bottom: 40px;
	}
	.frame1-home-frame183 {
		align-items: flex-start;
	}
	.frame1-home-frame183 > div:nth-child(1) {
		min-width: 100%;
		padding: 0;
		max-height: none;
	}
	.section-1-button-containers {
		gap: 24px;
		justify-content: center;
	}
	.section-1-button-containers a,
	.section-1-button-containers button {
		width: 80%;
		justify-content: center;
	}
	.frame1-home-group80 img {
		padding: 8px;
	}
}
@media (max-width: 550px) {
	.frame1-home-col08 {
		margin: 0;
		border-radius: 0;
		position: relative;
		padding: 36px 32px 0 32px;
	}
	.frame1-home-section4 {
		padding: 0 0 70px;
	}
	.frame1-home-container3 > div:nth-child(1) {
		max-width: 100vw;
	}
	.frame1-home-section2 {
		padding: 0 0 70px;
	}
	.frame1-home-container5 {
		padding: 0 32px;
	}
	.frame1-home-container2 {
		padding: 0;
	}
}
@media (max-width: 500px) {
	.frame1-home-section {
		padding: 70px 24px;
	}
	.frame1-home-section1 {
		padding: 70px 24px;
	}
	.frame1-home-oquee {
		padding: 0 24px;
	}
	.section-2-2 {
		padding: 32px 24px !important;
	}
	.frame1-home-container5 {
		padding: 0 24px;
	}
	.frame1-home-section3 {
		padding: 60px 0 90px;
		background-position-x: center;
		border-radius: 80px 0 0;
	}
	.frame1-home-container6 {
		padding: 70px 24px 70px;
	}
	.frame1-home-sectionjoinus {
		border-radius: 80px 0 0;
	}
	.frame1-home-frame12,
	.frame1-home-text088,
	.frame1-home-text093 {
		text-align: left !important;
		width: 100%;
	}
	.frame1-home-text091,
	.frame1-home-text083 {
		font-size: 36px;
	}
	.frame1-home-title3,
	.frame1-home-frame12 {
		align-items: flex-end;
	}
}

/* Slider Carousel */
.slider-container {
	max-width: calc(100vw - 32px);
}

.slide-item-container {
	padding-right: 16px;
}

.slide-item {
	width: 272px;
	height: 134px;
	background-color: #f5f5f5;
	border-radius: 8px;
}

/* Menu List */
.menu-container button {
	cursor: pointer;
}

.menu-container a {
	padding: 12px 0;
	color: #000;
	font-weight: 500;
}

.menu-container a:hover {
	color: rgb(241, 187, 12);
}

@media (max-width: 800px) {
	.rfm-overlay {
		display: none;
	}
}

@media (max-width: 600px) {
	.menu-container {
		display: block !important;
	}
	.slide-item-container {
		padding-right: 16px;
	}
	.slide-item,
	.slide-item img {
		width: 200px;
		height: 100px;
	}
}

.bm-cross-button {
	height: 24px;
	width: 24px;
}

.bm-cross {
	background: #9a9da0;
}

.bm-menu-wrap {
	position: fixed;
	height: 100%;
	top: 0;
	right: 0;
	width: 240px !important;
}

.bm-menu {
	background: #fff;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
}

.bm-morph-shape {
	fill: #373a47;
}

.bm-item-list {
	color: #b8b7ad;
	padding: 0.8em;
}

.bm-item {
	display: inline-block;
}

.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
	top: 0;
	right: 0;
}
@media (max-width: 400px) {
	.frame1-home-image31 {
		border-top-right-radius: 100px;
		border-bottom-right-radius: 100px;
	}
}

@media (max-width: 375px) {
	.frame1-home-section {
		padding: 70px 16px;
	}
	.frame1-home-section1 {
	}
	.frame1-home-oquee {
		padding: 0 16px;
	}
	.slider-container {
		max-width: calc(100vw - 14px);
	}
	.section-2-2 {
		padding: 32px 16px !important;
	}
	.frame1-home-container5 {
		padding: 0 16px;
	}
	.frame1-home-col08 {
		padding: 32px 16px 0;
	}
	.frame1-home-container6 {
		padding: 70px 16px 70px;
	}
}
